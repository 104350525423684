<!-- System: STA
    Purpose: projects invite modal helps user to add new 
            user from email in side the create/update
            project modal.
-->
<template>
  <div class="z-index">
    <!-- Invite Member By Email Modal -->
    <v-dialog
      max-width="700px"
      class="pa-0 white-bg z-index invite-modal"
      v-model="invite_modal"
      @click:outside="hideModal()"
    >
      <!-- Invite Team Member Button -->
      <template v-slot:activator="{}">
        <v-btn
          elevation="0"
          outlined
          @click="invite_modal = true"
          class="text-capitalize"
          color="#2758f6"
        >
          <v-icon color="#2758f6" class="float-left">mdi-plus-box</v-icon>
          <span class="ml-2 mt-1">Invite Team members</span></v-btn
        >
      </template>
      <!-- Invite Team Member Card -->
      <v-card class="white-bg">
        <v-card-title>
          <span class="headline">Invite team members</span>
        </v-card-title>
        <div class="project-invite-div">
          <div class="team-form-wrapper pt-3 p-0" v-if="invite_form">
            <!-- Invite Team Member Form -->
            <v-form
              @submit.prevent="show_invite_form"
              ref="inviteMemberForm"
              v-model="valid"
            >
              <v-row>
                <v-col
                  :key="i"
                  cols="12"
                  class="pb-3 float-left single-member p-0"
                  v-for="(account, i) in accounts"
                  v-if="accounts.length"
                >
                  <div class="col-4 float-left designation-member-p">
                    <!-- Invite Team Designation Field -->
                    <v-text-field
                      :name="'member_designation' + i"
                      placeholder="Designation"
                      type="text"
                      v-model="account.member_designation"
                      autocomplete="off"
                      :rules="designationNameRule"
                    ></v-text-field>
                  </div>
                  <div class="col-6 float-left email-member-p">
                    <!-- Invite Team Email Field -->
                    <v-text-field
                      :name="'member_email' + i"
                      placeholder="Email"
                      type="email"
                      autocomplete="off"
                      v-model="account.member_email"
                      :rules="emailRules"
                    ></v-text-field>
                  </div>
                  <div classs="col-2">
                    <!-- Add More Email Fields Button -->
                    <a
                      @click="accounts.splice(i, 1)"
                      class="btn float-left remove-member-btn col-1 delete-email-section mt-3"
                      v-if="i !== 0"
                    >
                      <img
                        src="../../assets/images/dashboard_icons/Minus_outline_icon.svg"
                      />
                    </a>
                  </div>
                  <div classs="col-2">
                    <a
                      @click="addRow()"
                      class="add-member-btn p-0 float-left mt-6"
                    >
                      <img
                        src="../../assets/images/dashboard_icons/Plus_outline_icon.svg"
                      />
                    </a>
                  </div>
                </v-col>
              </v-row>
              <!-- Invite Team Members Button -->
              <div class="p-0">
                <v-btn
                  class="create-project-button"
                  color="#2758f6"
                  type="submit"
                  :disabled="!valid"
                >
                  Invite Members
                </v-btn>
              </div>
            </v-form>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { emailRules, required, DesignationRules } from "@/Constants/Const";
export default {
  name: "ProjectInviteMember",
  data() {
    return {
      invite_modal: false,
      invite_form: true,
      designationNameRule: DesignationRules,
      emailRules: emailRules,
      valid: true,
      accounts: [
        {
          member_email: "",
          member_designation: "",
        },
      ],
    };
  },
  computed: {
    invitedMembersEmails() {
      let existingMembers = this.$store.state.custom.inviteMembersFormData;
      if (existingMembers.emails) return existingMembers.emails;
      return {};
    },
  },

  methods: {
    /**
     * This function is resposible for clearing
     * form data when modal is close
     */
    hideModal() {
      this.invite_modal = false;
      this.$refs.inviteMemberForm.reset();
    },
    /**
     * This function is resposible for Submiting
     * or adding form data when invite btn hits
     */
    show_invite_form(e) {
      e.preventDefault();
      let designations = this.accounts.map((account) => {
        return account.member_designation;
      });
      let emails = this.accounts.map((account) => {
        return account.member_email;
      });
      let inviteMem = {
        designations: designations,
        emails: emails,
      };
      this.accounts = [
        {
          member_email: "",
          member_designation: "",
        },
      ];
      this.$store.commit("custom/pushIntoInviteMembersFormData", inviteMem);
      this.hideModal();
      this.$emit("create_form_value");
    },
    /**
     * This function is resposible for adding
     * new designation and email rows in form
     */
    addRow() {
      this.accounts.push({
        member_email: "",
        member_designation: "",
      });
    },
  },
};
</script>

<style scoped>
.blue--text {
  color: blue !important;
}
.remove-member,
.add-member {
  margin: 3px 0px 0px;
  color: #fff;
  padding-top: 3px !important;
  border-radius: unset;
}

.remove-member {
  background-color: rgb(251, 51, 59);
}

.add-member {
  background-color: rgb(0, 190, 118);
}

.error-msg {
  color: tomato;
  font-size: 12px;
}

.project-invite-div input {
  height: 48px;
  border: 1px solid #cbdaf2;
  border-radius: 3px !important;
}

.project-invite-div input:hover {
  border: 1px solid #2758f6;
}

.add-member-btn {
  background-color: unset !important;
}

.project-invite-div img {
  height: 30px;
  margin-top: 7px;
}

.add-member-btn:hover {
  cursor: pointer;
}

.remove-member-btn:hover {
  cursor: pointer;
}

.create-project-button {
  height: 48px;
  border-radius: 3px !important;
  background-color: #2758f6;
  color: white;
  font-weight: 600;
  font-size: 20px;
  margin-top: 16px;
  margin-bottom: 17px;
  margin-left: 280px;
  width: 220px;
  border: none;
}

.create-project-button:hover {
  color: white;
  box-shadow: 0px 5px 12px 0px rgba(23, 70, 222, 0.4) !important;
  transform: scale(1.01);
  background-color: #2758f6 !important;
  cursor: pointer !important;
  border: none;
}
@media (max-width: 991px) {
  .delete-email-section {
    padding: 11px 0px !important;
  }
}
@media (max-width: 500px) {
  .project-invite-div img {
    height: 27px;
    width: 30px;
  }
  .designation-member-p,
  .email-member-p {
    max-width: 100% !important;
  }
  .designation-member-p {
    margin-bottom: 16px;
  }
}
@media (max-width: 375px) {
  .create-project-button {
    height: 43px;
    font-size: 16px;
    width: 200px;
  }
}
</style>
